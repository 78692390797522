import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

import { useNavigationStore } from "../components/nav/state";
import { extractFeatureFlags } from "../features/users/featureFlags";
import { getConnectionNodesTyped } from "../utils/relay";
import { GetBasicData } from "./queries.graphql";
import { useQueryWithResponseHandler } from "./useQueryWithResponseHandler";

type UseBasicDataProps = {
  fetch?: boolean;
};

type RouteParams = {
  workspace?: string;
  fileId?: string;
};

const OrderByTier = {
  FREE: 0,
  PRO: 1,
  STUDIO: 2,
  ENTERPRISE: 3,
};

const orderMembersShipsByTier = (memberships) => {
  const sortedMemberShips = [...memberships];

  sortedMemberShips.sort((a, b) => {
    const aVal = OrderByTier[a.workspace.planTier];
    const bVal = OrderByTier[b.workspace.planTier];
    return aVal > bVal ? -1 : 1;
  });

  return sortedMemberShips;
};

export default function useBasicData(props?: UseBasicDataProps) {
  const params = useParams<keyof RouteParams>() as RouteParams;
  const { activeWorkspaceName, setActiveWorkspaceName } = useNavigationStore();
  const workspaceName = params.workspace;

  const hasWorkspaceName = !!workspaceName && workspaceName !== "me";

  const {
    state: query,
    execute: refreshBasicData,
    data: { me: user, workspace, activeWorkspace, file },
    error,
  } = useQueryWithResponseHandler(
    {
      query: GetBasicData,
      variables: {
        hasWorkspaceName,
        workspaceName: workspaceName ?? "",
        activeWorkspaceName: activeWorkspaceName ?? "",
        hasActiveWorkspaceName: !!activeWorkspaceName,
        hasFileId: !!params.fileId,
        fileId: params.fileId ?? "",
      },
      requestPolicy: props?.fetch ? "network-only" : "cache-only",
    },
    {},
  );
  const featureFlags = useMemo(
    () =>
      user && workspace
        ? extractFeatureFlags(user.featureFlags, workspace.featureFlags)
        : {},
    [user, workspace],
  );

  useEffect(() => {
    // Assigns active workspace in to local storage only if user is switching to a context of a workspace that they are part of
    // If there is no active workspace assigned, we use the first workspace in user's memberships

    if (user) {
      const memberships = getConnectionNodesTyped(user.memberships);
      const isPartOfWorkspace = memberships.some(
        ({ workspace }) => workspace.name === workspaceName,
      );

      // Handles not set yet case, logged out case, and when user gets removed from workspace
      const isPartOfActiveWorkspace = memberships.some(
        ({ workspace }) => workspace.name === activeWorkspaceName,
      );

      if (!isPartOfActiveWorkspace) {
        const membershipsOrderedByTier = orderMembersShipsByTier(memberships);
        const firstWorkspaceName = membershipsOrderedByTier[0].workspace.name;
        setActiveWorkspaceName(firstWorkspaceName);
      } else if (isPartOfWorkspace) {
        setActiveWorkspaceName(workspaceName);
      }
    }
  }, [user, workspaceName, activeWorkspaceName]);

  const userWorkspaces = useMemo(() => {
    if (user) {
      const nonPersonalWorkspaces = getConnectionNodesTyped(user.memberships)
        .filter(
          (membership) =>
            membership.workspace.id !== user.personalWorkspace?.id,
        )
        .map((membership) => membership.workspace);
      return [user.personalWorkspace, ...nonPersonalWorkspaces];
    }
    return [];
  }, [user]);

  return {
    fetching: query.fetching,
    error,
    user,
    workspace: workspace || file?.workspace || null,
    activeWorkspace,
    userWorkspaces,
    file,
    featureFlags,
    refreshBasicData,
  };
}
