import { useCallback } from "react";

import {
  bindMenu,
  bindMenuTrigger,
  DisabledArea,
  Link,
  MenuItem,
  useMenuState,
} from "..";
import { DataMenu } from "./DataMenu";
import { MenuItemData } from "./types";

interface DataMenuItemProps {
  data: MenuItemData;
  onCloseMenu?: () => void;
}

const DataMenuItemWithSubMenu = ({ data, onCloseMenu }: DataMenuItemProps) => {
  const subMenuState = useMenuState({
    openOnClick: true,
    useDefaultOrigins: true,
  });
  return [
    <MenuItem
      key="item"
      {...bindMenuTrigger(subMenuState)}
      icon={data.icon}
      disabled={data.disabled}
      selected={data.selected}
    >
      {data.name}
    </MenuItem>,
    <DataMenu
      key="submenu"
      {...bindMenu(subMenuState)}
      items={data.subMenu}
      onCloseMenu={onCloseMenu}
    />,
  ];
};

export const DataMenuItem = ({ data, onCloseMenu }: DataMenuItemProps) => {
  const onClick = useCallback(() => {
    data.onClick?.();
    if (data.closeOnClick) onCloseMenu();
  }, [data.onClick, data.closeOnClick]);

  return data.subMenu ? (
    <DataMenuItemWithSubMenu data={data} onCloseMenu={onCloseMenu} />
  ) : (
    <DisabledArea disabled={data.disabled} title={data.disabledTooltipTitle}>
      <MenuItem
        component={data.route ? Link : undefined}
        href={data.route || undefined}
        icon={data.icon}
        selected={data.selected}
        onClick={onClick}
        disabled={data.disabled}
      >
        {data.name}
      </MenuItem>
    </DisabledArea>
  );
};
