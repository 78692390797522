import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

import { getAssetURL } from "../../utils/assets";

type Props = { topLevel?: boolean; errorData?: Error };

const links = [
  {
    name: "Status",
    href: "https://status.layer.ai/",
    description: "Check out our status page for any incidents.",
    icon: (
      <FontAwesomeIcon
        className="w-5 h-5 text-layer-300"
        icon={icon({
          name: "signal-bars",
          family: "classic",
          style: "solid",
        })}
      />
    ),
  },
  {
    name: "Guides",
    href: "https://help.layer.ai/en/",
    description: "Guides that cover popular requests.",
    icon: (
      <FontAwesomeIcon
        className="w-5 h-5 text-layer-300"
        icon={icon({
          name: "book-bookmark",
          family: "sharp",
          style: "solid",
        })}
      />
    ),
  },
  {
    name: "Logout",
    href: "/logout",
    description:
      "Logging out and back in may help if you're repeatedly facing this issue.",
    icon: (
      <FontAwesomeIcon
        className="w-5 h-5 text-layer-300"
        icon={icon({
          name: "arrow-right-from-bracket",
          family: "sharp",
          style: "solid",
        })}
      />
    ),
  },
];

export default function Error500({ topLevel, errorData }: Props) {
  return (
    <div className="w-screen h-screen">
      <main
        className={classNames({
          "w-full px-6 py-16 mx-auto max-w-7xl sm:pb-24 lg:px-8 h-full": true,
          "h-full": !topLevel,
        })}
      >
        <img
          className="w-auto h-16 mx-auto sm:h-12"
          src={getAssetURL("branding/mark_color.png")}
          alt="Layer AI"
        />
        <div className="max-w-2xl mx-auto mt-20 text-center sm:mt-24">
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-layer-50 sm:text-5xl">
            Ooops!
          </h1>
          <p className="mt-4 text-base leading-7 text-layer-300 sm:mt-6 sm:text-lg sm:leading-8">
            Sorry, it seems Layer is having issues serving this page. Our
            engineers have been notified of the issue and will be working to
            resolve it as soon as possible.
          </p>
          {errorData ? (
            <div className="block">
              <pre className="p-4 overflow-x-auto rounded-md bg-layer-800 text-layer-300">
                {errorData.stack ?? Error.prototype.toString.call(errorData)}
              </pre>
            </div>
          ) : null}
        </div>
        <div className="flow-root max-w-lg mx-auto mt-16 sm:mt-20">
          <h2 className="sr-only">Popular pages</h2>
          <ul role="list" className="-mt-6 gap-2 flex flex-col">
            {links.map((link, linkIdx) => (
              <li
                key={linkIdx}
                className="relative flex items-center py-6 pr-6 pl-6 gap-x-6 group hover:bg-layer-800 border rounded-xl border-layer-700"
              >
                <div className="flex items-center justify-center flex-none w-10 h-10 rounded-lg shadow-sm ring-1 ring-layer-400/30">
                  {link.icon}
                </div>
                <div className="flex-auto">
                  <h3 className="text-base font-semibold leading-6 text-layer-200">
                    <a href={link.href}>
                      <span
                        className="absolute inset-0 group-hover:underline"
                        aria-hidden="true"
                      />
                      {link.name}
                    </a>
                  </h3>
                  <p className="mt-0.5 text-sm leading-6 text-layer-300">
                    {link.description}
                  </p>
                </div>
                <div className="self-center flex-none">
                  <FontAwesomeIcon
                    className="w-4 h-4 text-layer-400"
                    icon={icon({
                      name: "chevron-right",
                      family: "sharp",
                      style: "solid",
                    })}
                  />
                </div>
              </li>
            ))}
          </ul>
          <div className="flex justify-center mt-10">
            <a
              href="/"
              className="text-sm font-semibold leading-6 text-purple-400"
            >
              <span aria-hidden="true">&larr;</span>
              Back to home
            </a>
          </div>
        </div>
      </main>
    </div>
  );
}
