import { useParams } from "react-router-dom";

import { useQueryWithResponseHandler } from "../../../../../../hooks";
import { getConnectionNodesTyped } from "../../../../../../utils/relay";
import { Typography } from "../../../../../ui-v2";
import { SessionItem } from "./components/SessionItem/SessionItem";
import { GetRecentSessions } from "./queries.graphql";

export const RecentSessions = () => {
  const { workspace } = useParams();
  const { data, error } = useQueryWithResponseHandler({
    query: GetRecentSessions,
    variables: {
      workspaceName: workspace,
      getSessionsInput: {
        status: ["ACTIVE"],
        first: 5,
        mineOnly: true,
      },
    },
  });

  const sessions = getConnectionNodesTyped(data?.workspace?.sessions);

  if (error || !sessions) {
    return null;
  }

  return (
    <nav>
      <Typography
        fontWeight="700"
        variant="micro"
        color="secondary.300"
        sx={{ display: "inline-block", mb: 2, ml: 1 }}
      >
        Recent Sessions
      </Typography>
      {sessions.map((session) => (
        <SessionItem
          key={session.id}
          name={session.name}
          to={`/${workspace}/session/${session.id}`}
          iconUrl={session.previewUrls[0]}
        />
      ))}
    </nav>
  );
};
