import { styled } from "@mui/material";
import React from "react";

import { Link, LinkProps } from "../Link/Link";

export interface BreadcrumbProps extends LinkProps {
  label: string;
  icon?: React.ReactElement;
  active?: boolean;
}

const StyledLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== "active",
})({
  fontWeight: 600,
  fontSize: "inherit",
  color: "inherit",
});

const IconContainer = styled("span", {
  shouldForwardProp: (prop) => prop !== "active",
})(({ theme }) => ({
  marginRight: theme.spacing(1.5),
}));

export const Breadcrumb = React.forwardRef<HTMLAnchorElement, BreadcrumbProps>(
  ({ label, icon, ...rest }: BreadcrumbProps, ref) => {
    return (
      <StyledLink {...rest} ref={ref}>
        {icon && <IconContainer>{icon}</IconContainer>}
        {label}
      </StyledLink>
    );
  },
);
