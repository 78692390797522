export interface WorkspaceFeatureFlags {
  show3DMode?: boolean;
}

export interface UserFeatureFlags {
  showForgeSession?: boolean;
  showStyleStudioV2?: boolean;
  showHomepageV2?: boolean;
  showInferenceFormV2?: boolean;
  showConnectionIndicator?: boolean;
  show3DMode?: boolean;
  showLiveCanvas?: boolean;
  showNewNavigation?: boolean;
}

const featureFlagMapping: {
  [key: string]: keyof UserFeatureFlags | keyof WorkspaceFeatureFlags;
} = {
  show_new_navigation: "showNewNavigation",
  show_forge_session: "showForgeSession",
  show_style_studio_v2: "showStyleStudioV2",
  show_homepage_v2: "showHomepageV2",
  show_inference_form_v2: "showInferenceFormV2",
  show_connection_indicator: "showConnectionIndicator",
  show_live_canvas: "showLiveCanvas",
  show_3d_mode: "show3DMode",
};

export function extractFeatureFlags(
  userFeatureFlagList: string[],
  workspaceFeatureFlagList: string[],
) {
  const featureFlags: UserFeatureFlags & WorkspaceFeatureFlags = {};
  userFeatureFlagList.forEach((flag) => {
    const key = featureFlagMapping[flag];
    if (key) {
      featureFlags[key] = true;
    }
  });

  // Workspace feature flags will override any user level ones if truthy
  workspaceFeatureFlagList.forEach((flag) => {
    const key = featureFlagMapping[flag];
    if (key) {
      featureFlags[key] = true;
    }
  });

  // All features enabled in dev environment
  if (__DEV__) {
    for (const flag of Object.values(featureFlagMapping)) {
      featureFlags[flag] = true;
      featureFlags.showNewNavigation = false;
    }
  }

  return featureFlags;
}
