import {
  brands,
  icon,
  regular,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import { SxProps, TooltipProps } from "@mui/material";
import { useTheme } from "@mui/system";
import { forwardRef, useMemo } from "react";

import {
  discordUrl,
  supportUrl,
} from "../../../../../../../../utils/constants";
import { useNavigationStore } from "../../../../../../../nav/state";
import {
  Avatar,
  bindMenu,
  bindMenuTrigger,
  Box,
  Button,
  Divider,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  useMenuState,
} from "../../../../../../../ui-v2";

interface WorkspaceDropdownProps {
  allWorkspaces: WorkspaceDropdownInfo[];
  currentWorkspaceId: string;
  personalWorkspaceId: string;
  userEmail: string;
  userFullName?: string;
  userProfilePictureUrl?: string;
  handleIntercomMessage?: () => void;
  startOpen?: boolean;
}

export type WorkspacePermission =
  | "workspace:settings"
  | "workspace:billing"
  | "workspace:members";

export interface WorkspaceDropdownInfo<P extends string = string> {
  id: string;
  name: string;
  displayName?: string;
  profilePicture?: string;
  planTier?: "ENTERPRISE" | "FREE" | "PRO" | "STUDIO";
  myPermissions?: (WorkspacePermission | P)[];
}

function getTierName(tier: WorkspaceDropdownInfo["planTier"]) {
  switch (tier) {
    case "ENTERPRISE":
      return "Enterprise";
    case "FREE":
      return "Free";
    case "PRO":
      return "Pro";
    case "STUDIO":
      return "Studio";
    default:
      return "";
  }
}

function getWorkspaceDisplayName(workspace: WorkspaceDropdownInfo) {
  return workspace.displayName || workspace.name;
}

function getWorkspaceInitial(workspace: WorkspaceDropdownInfo) {
  return (getWorkspaceDisplayName(workspace) || "w").charAt(0).toUpperCase();
}

interface WorkspaceAvatarProps {
  workspace: WorkspaceDropdownInfo;
  sx: SxProps;
  isLink?: boolean;
  onClick?: (e?: any) => void;
  tooltipPlacement?: TooltipProps["placement"];
  disableTooltip?: TooltipProps["disableHoverListener"];
}

const WorkspaceAvatar = forwardRef<HTMLAnchorElement, WorkspaceAvatarProps>(
  (
    {
      workspace,
      sx,
      onClick,
      isLink = true,
      tooltipPlacement = "top",
      disableTooltip = false,
    }: WorkspaceAvatarProps,
    ref,
  ) => {
    const linkProps = {
      component: Link,
      href: `/${workspace.name}`,
    };

    return (
      <Tooltip
        title={workspace.displayName}
        placement={tooltipPlacement}
        arrow
        enterDelay={300}
        disableHoverListener={disableTooltip}
        disableFocusListener={disableTooltip}
      >
        <Avatar
          ref={ref}
          {...(isLink && linkProps)}
          onClick={onClick}
          sx={{
            color: "primary.600",
            bgcolor: "primary.200",
            fontWeight: "bold",
            borderRadius: 2,
            "&&&": {
              border: "none",
            },
            ...sx,
          }}
          src={workspace.profilePicture}
          alt={getWorkspaceDisplayName(workspace)}
        >
          {getWorkspaceInitial(workspace)}
        </Avatar>
      </Tooltip>
    );
  },
);

export const WorkspaceDropdown = (props: WorkspaceDropdownProps) => {
  const {
    allWorkspaces,
    currentWorkspaceId,
    personalWorkspaceId,
    userEmail,
    userFullName,
    userProfilePictureUrl,
    handleIntercomMessage,
    startOpen = false,
  } = props;

  const theme = useTheme();
  const { expanded } = useNavigationStore();
  const { palette } = theme;

  const workspaces = useMemo(
    () =>
      allWorkspaces.reduce(
        (acc, workspace) => {
          if (workspace.id === currentWorkspaceId) {
            acc.current = workspace;
          } else {
            acc.other.push(workspace);
          }

          if (workspace.id === personalWorkspaceId) {
            acc.personal = workspace;
          } else {
            acc.team.push(workspace);
          }
          return acc;
        },
        {
          current: null as WorkspaceDropdownInfo | null,
          personal: null as WorkspaceDropdownInfo | null,
          team: [] as WorkspaceDropdownInfo[],
          other: [] as WorkspaceDropdownInfo[],
        },
      ),
    [allWorkspaces, currentWorkspaceId, personalWorkspaceId],
  );

  const isPersonalWorkspace = currentWorkspaceId === personalWorkspaceId;

  const mainMenuState = useMenuState({ startOpen });
  const workspacesMenuState = useMenuState({
    parentMenuState: mainMenuState,
    openOnHover: true,
    closeOnLeave: true,
  });
  const billingMenuState = useMenuState({
    parentMenuState: mainMenuState,
    openOnHover: true,
    closeOnLeave: true,
  });

  const workspaceSubtitle: string[] = [];
  if (workspaces.current?.planTier) {
    workspaceSubtitle.push(`${getTierName(workspaces.current.planTier)} Plan`);
  }
  workspaceSubtitle.push(isPersonalWorkspace ? "Personal Space" : "Team Space");

  if (!workspaces.current) {
    return null;
  }

  return (
    <>
      <Button
        variant="text"
        color="secondary"
        sx={{
          transition: "all 300ms ease-out",
          paddingLeft: 0,
          paddingRight: 1,
          paddingTop: 1,
          paddingBottom: 1,
          fontWeight: 600,
          minWidth: 0,
          maxWidth: expanded ? "168px" : "28px",
          border: "none",
          justifyContent: "flex-start",
          backgroundColor: mainMenuState.open
            ? theme.palette.secondary[900]
            : "transparent",
          boxShadow: mainMenuState.open
            ? `inset 0 0 0 0.5px ${theme.palette.secondary[700]}`
            : "none",

          "&:hover": {
            boxShadow: `inset 0 0 0 0.5px ${theme.palette.secondary["700/50"]}`,
          },

          "& .MuiButton-startIcon": {
            marginLeft: "-5px",
            transform: expanded ? "none" : "translateX(-1px)",
            transition: "all 300ms ease-out",
          },

          "& .MuiButton-icon": {
            marginLeft: expanded ? 0 : "1px",
            marginRight: "5px",
          },
        }}
        startIcon={
          <Box
            sx={{
              height: theme.spacing(7),
              width: theme.spacing(7),
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <WorkspaceAvatar
              workspace={workspaces.current}
              sx={{
                width: expanded ? 20 : 24,
                height: expanded ? 20 : 24,
                fontSize: 13,
              }}
              disableTooltip={expanded}
              tooltipPlacement="right"
            />
          </Box>
        }
        endIcon={
          expanded && (
            <span
              style={{
                fontSize: "0.85em",
                color: palette.secondary[300],
                marginLeft: 8,
              }}
            >
              <Icon
                icon={icon({
                  name: "angle-down",
                  family: "sharp",
                  style: "solid",
                })}
              />
            </span>
          )
        }
        {...bindMenuTrigger(mainMenuState)}
      >
        {expanded && (
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              transition: "all 300ms ease-out",
              lineHeight: 1.5,
              maxWidth: expanded ? 200 : 0,
            }}
          >
            {getWorkspaceDisplayName(workspaces.current)}
          </span>
        )}
      </Button>

      <Menu
        {...bindMenu(mainMenuState)}
        MenuListProps={{
          component: "nav",
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: 0 }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        sx={{
          "& .MuiPaper-root": {
            width: "204px",
            minWidth: "204px",
            paddingTop: 0,
            marginTop: 1,
            marginLeft: -2,
          },
        }}
      >
        <Box
          sx={{
            backgroundColor: palette.secondary["700/25"],
            borderRadius: 5,
            borderBottom: `1px solid ${palette.secondary[700]}`,
            paddingBottom: 1,
            marginBottom: 2,
            boxSizing: "content-box",
          }}
        >
          <Stack direction="column" spacing={2.5} sx={{ p: 3.5, pb: 3 }}>
            <Stack direction="row" spacing={2} alignItems="start">
              <WorkspaceAvatar
                workspace={workspaces.current}
                sx={{
                  width: "72px",
                  height: "72px",
                  pointerEvents: "none",
                  borderRadius: 4,
                  flexShrink: 0,
                  fontSize: 32,
                  transform: "translate(-4px, -4px)",
                }}
              />

              <Stack
                direction="row"
                justifyContent="end"
                flexGrow={1}
                spacing={1}
                sx={{ transform: "translate(2px, -4px)" }}
              >
                {workspaces.personal && (
                  <Tooltip
                    title="Switch to Personal Workspace"
                    arrow
                    placement="top"
                  >
                    <div>
                      <WorkspaceAvatar
                        workspace={workspaces.personal}
                        sx={{
                          width: 20,
                          height: 20,
                          borderRadius: 1.5,
                          fontSize: 12,
                        }}
                      />
                    </div>
                  </Tooltip>
                )}

                {workspaces.team.slice(0, 2).map((workspace) => (
                  <Tooltip
                    placement="top"
                    key={workspace.id}
                    title={`Switch to ${workspace.displayName}`}
                    arrow
                  >
                    <div>
                      <WorkspaceAvatar
                        workspace={workspace}
                        sx={{
                          width: 20,
                          height: 20,
                          borderRadius: 1.5,
                          fontSize: 12,
                        }}
                      />
                    </div>
                  </Tooltip>
                ))}

                <IconButton
                  size="small"
                  variant="secondary"
                  aria-label="Switch Workspace"
                  sx={{
                    minWidth: 20,
                    minHeight: 20,
                    borderRadius: 1.5,
                    padding: 0,
                    border: "none",
                    fontSize: 13,
                    boxShadow: workspacesMenuState.open
                      ? `inset 0 0 0 0.5px ${palette.secondary["300/75"]}`
                      : `inset 0 0 0 0.5px ${palette.secondary["400/75"]}`,
                    color: workspacesMenuState.open
                      ? "secondary.50"
                      : "secondary.300",
                    backgroundColor: workspacesMenuState.open
                      ? "secondary.600"
                      : "transparent",
                  }}
                  {...bindMenuTrigger(workspacesMenuState)}
                >
                  <Icon
                    icon={icon({
                      name: "ellipsis",
                      family: "classic",
                      style: "regular",
                    })}
                  />
                </IconButton>
              </Stack>
            </Stack>
            <Stack direction="column" spacing={2}>
              <Typography
                sx={{
                  width: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
                fontWeight={600}
                variant="regular"
                lineHeight={1}
              >
                {getWorkspaceDisplayName(workspaces.current)}
              </Typography>
              <Typography variant="micro" color="text.secondary" lineHeight={1}>
                {workspaceSubtitle.join(" · ")}
              </Typography>
            </Stack>
          </Stack>

          {workspaces.current.myPermissions?.includes("workspace:settings") && (
            <MenuItem
              component={Link}
              href={`/${workspaces.current.name}/settings/general`}
              onClick={mainMenuState.onClose}
              icon={
                <Icon
                  icon={icon({
                    name: "square-sliders-vertical",
                    family: "sharp",
                    style: "solid",
                  })}
                />
              }
              variant="primary"
            >
              General Settings
            </MenuItem>
          )}
          {workspaces.current.myPermissions?.includes("workspace:members") && (
            <MenuItem
              component={Link}
              href={`/${workspaces.current.name}/settings/members`}
              onClick={mainMenuState.onClose}
              icon={
                <Icon
                  icon={icon({
                    name: "square-user",
                    family: "sharp",
                    style: "solid",
                  })}
                />
              }
              variant="primary"
            >
              Manage Members
            </MenuItem>
          )}
          {workspaces.current.myPermissions?.includes("workspace:billing") && (
            <MenuItem
              {...bindMenuTrigger(billingMenuState)}
              hasSubMenu
              icon={
                <Icon
                  icon={icon({
                    name: "square-dollar",
                    family: "sharp",
                    style: "solid",
                  })}
                />
              }
              variant="primary"
            >
              Plans & Billing
            </MenuItem>
          )}
        </Box>
        {workspaces.current.myPermissions?.includes("workspace:settings") && [
          <MenuItem
            key="access-tokens"
            component={Link}
            href="/settings/tokens"
            icon={<Icon icon={regular("share-nodes")} rotation={90} />}
            sx={{
              color: "text.primary",
              paddingRight: theme.spacing(8),
              fontWeight: 500,
            }}
          >
            Access Tokens
          </MenuItem>,
          <Divider key="divider" />,
        ]}

        <MenuItem
          component={Link}
          href={supportUrl}
          target="_blank"
          onClick={mainMenuState.onClose}
          icon={<Icon icon={regular("circle-question")} />}
          sx={{
            color: "text.secondary",
            paddingRight: theme.spacing(8),
            fontWeight: 500,
          }}
        >
          Layer Docs & FAQs
        </MenuItem>
        <MenuItem
          icon={<Icon icon={regular("message-lines")} />}
          onClick={() => {
            if (handleIntercomMessage) {
              handleIntercomMessage();
            }

            mainMenuState.onClose();
          }}
          sx={{
            color: "text.secondary",
            paddingRight: theme.spacing(8),
            fontWeight: 500,
          }}
        >
          Contact Support
        </MenuItem>
        <Divider />
        <MenuItem
          component={Link}
          href={discordUrl}
          target="_blank"
          onClick={mainMenuState.onClose}
          icon={
            <Icon
              icon={brands("discord")}
              style={{ transform: "translateX(-1px)" }}
            />
          }
        >
          Join our Discord
        </MenuItem>
        <Divider />
        <Box
          sx={{
            paddingLeft: 4,
            paddingRight: 4,
            paddingTop: 1,
            paddingBottom: 2,
          }}
        >
          <Stack direction="column" spacing={1}>
            <Typography
              variant="micro"
              color="text.secondary"
              fontWeight="light"
            >
              Signed in as
            </Typography>
            <Stack direction="row" spacing={2} alignItems={"center"}>
              <Avatar
                sx={{
                  width: "16px",
                  height: "16px",
                  fontSize: "16px",
                  backgroundColor: "primary.200",
                  color: "primary.800",
                }}
                src={userProfilePictureUrl}
                alt={userFullName || userEmail}
              />
              <Stack direction="column" spacing={1}>
                <Typography variant="small" color="text.primary" lineHeight={1}>
                  {userFullName || userEmail}
                </Typography>
                <Typography
                  variant="mini"
                  color="text.secondary"
                  lineHeight={1}
                >
                  {userEmail}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <MenuItem
          component={Link}
          href={"/logout"}
          icon={
            <Icon
              icon={regular("arrow-right-from-bracket")}
              style={{ transform: "translateX(2px)" }}
            />
          }
        >
          Log Out
        </MenuItem>
      </Menu>

      <Menu
        {...bindMenu(billingMenuState)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        sx={{
          "& .MuiPaper-root": {
            marginLeft: 3.5,
            marginTop: -2.5,
          },
        }}
      >
        <MenuItem
          component={Link}
          href={`/${workspaces.current?.name}/settings/plans`}
          onClick={mainMenuState.onClose}
          icon={<Icon icon={regular("rectangle-pro")} />}
        >
          Manage Plan
        </MenuItem>
        <MenuItem
          component={Link}
          href={`/${workspaces.current?.name}/settings/billing`}
          onClick={mainMenuState.onClose}
          icon={<Icon icon={regular("credit-card-front")} />}
        >
          Billing & Payment
        </MenuItem>
      </Menu>

      <Menu
        {...bindMenu(workspacesMenuState)}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        sx={{
          "& .MuiPaper-root": {
            width: "210px",
            marginLeft: 8.5,
            marginTop: -2.75,
          },
        }}
      >
        <Box
          padding="0px 8px 8px 8px"
          borderBottom={`1px solid ${palette.secondary[700]}`}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="mini" color="text.secondary" fontWeight={600}>
            Switch Workspace
          </Typography>

          <IconButton
            size="small"
            variant="secondary"
            aria-label="Manage Memberships"
            component={Link}
            href="/settings/workspaces"
            onClick={mainMenuState.onClose}
            sx={{
              minWidth: 24,
              minHeight: 24,
              borderRadius: 1.5,
              padding: 0,
              border: "none",
              color: "secondary.300",
              backgroundColor: "transparent",

              "&:hover": {
                color: "secondary.100",
              },
            }}
          >
            <Icon
              icon={icon({
                name: "gear",
                family: "sharp",
                style: "solid",
              })}
              size="medium"
            />
          </IconButton>
        </Box>
        {workspaces.personal && (
          <Box padding={2}>
            <Typography variant="micro" color="text.secondary" fontWeight={600}>
              Personal Workspace
            </Typography>
          </Box>
        )}
        {workspaces.personal && (
          <MenuItem
            component={Link}
            href={`/${workspaces.personal.name}`}
            onClick={mainMenuState.onClose}
            selected={workspaces.current.id === workspaces.personal.id}
            icon={
              <WorkspaceAvatar
                workspace={workspaces.personal}
                isLink={false}
                sx={{
                  width: 16,
                  height: 16,
                  fontSize: 12,
                  borderRadius: 1,
                }}
                onClick={mainMenuState.onClose}
              />
            }
            sx={{
              fontWeight: 500,
            }}
          >
            {getWorkspaceDisplayName(workspaces.personal)}
          </MenuItem>
        )}
        {workspaces.team.length > 0 && (
          <Box padding={2}>
            <Typography variant="micro" color="text.secondary" fontWeight={600}>
              Team Workspaces
            </Typography>
          </Box>
        )}
        {workspaces.team.map((workspace) => (
          <MenuItem
            key={workspace.id}
            component={Link}
            href={`/${workspace.name}`}
            onClick={mainMenuState.onClose}
            selected={workspaces.current.id === workspace.id}
            icon={
              <WorkspaceAvatar
                isLink={false}
                workspace={workspace}
                sx={{
                  width: 16,
                  height: 16,
                  fontSize: 10,
                  borderRadius: 1,
                }}
                onClick={mainMenuState.onClose}
              />
            }
          >
            {getWorkspaceDisplayName(workspace)}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem
          component={Link}
          href="/new"
          onClick={mainMenuState.onClose}
          icon={<Icon icon={regular("plus")} />}
        >
          Create New Workspace
        </MenuItem>
      </Menu>
    </>
  );
};
