import useSelectPlan from "../../../../hooks/useSelectPlan";
import config from "../../../../utils/config";

type BillingPeriod = "MONTH" | "YEAR";

type PlanTier = "FREE" | "PRO" | "STUDIO" | "ENTERPRISE";

export interface UpgradePromptWorkspace {
  id: string;
  planTier: PlanTier;
}

export interface UpgradePromptUser {
  personalWorkspace?: {
    planTier: PlanTier;
  };
}

export const useUpgradePromptData = (
  minimumPlanTierRequired: PlanTier,
  workspace: UpgradePromptWorkspace,
  user: UpgradePromptUser,
) => {
  const selectPlan = useSelectPlan();
  const offeredPlans = config.OFFERED_PLANS;
  const workspacePlan = user.personalWorkspace
    ? user.personalWorkspace.planTier
    : workspace.planTier;

  const minimumIndex = offeredPlans.findIndex(
    (plan) => plan.tier === minimumPlanTierRequired,
  );
  const workspaceIndex = offeredPlans.findIndex(
    (plan) => plan.tier === workspacePlan,
  );

  const handleSelectPlan = () => {
    selectPlan(
      offeredPlans[minimumIndex],
      "MONTH" as BillingPeriod,
      workspace.id,
      false,
    );
  };

  return {
    user,
    workspace,
    offeredPlans,
    workspacePlan,
    minimumIndex,
    workspaceIndex,
    handleSelectPlan,
  };
};
