import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Box, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import { OfferedPlanConstraints } from "../../../../utils/config";
import { capitalize } from "../../../../utils/strings";
import { Button, Grid, Icon } from "../../";
import { PlanTier, Size } from "../UpgradePrompt";

const styleLabelByKey = {
  briaStyles: "BRIA Styles",
  openaiStyles: "Open AI Styles",
  stabilityaiStyles: "Stability AI Styles",
  urlStyles: "Url Styles",
  bflProStyles: "Black Forest Labs Pro Styles",
};

export const UpgradePromptBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  overflow: "hidden",
  maxWidth: theme.spacing(150),
  borderRadius: "6px",
  borderColor: theme.palette.secondary[600],
  borderStyle: "solid",
  borderWidth: 1,
}));

export const CallToActionContainer = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "left",
  padding: theme.spacing(8),
  color: "#fff",
  backgroundColor: theme.palette.secondary[700],
  flexGrow: 1,
  textAlign: "left",
}));

export const UpgradePromptTextContainer = styled(Stack)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

export const FeaturesList = styled(Box)(({ theme }) => ({
  color: theme.palette.secondary[300],
  backgroundColor: theme.palette.secondary[800],
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(8),
  paddingRight: theme.spacing(6),
  paddingLeft: theme.spacing(6),
  minWidth: "230px",
  maxWidth: "230px",
}));

export const FeaturesListInner = styled(Stack)(({ theme }) => ({
  color: theme.palette.secondary[200],
  paddingTop: theme.spacing(1),
}));

export const IconContainer = styled(Box)(({ theme }) => ({
  width: 48,
  height: 48,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: theme.typography.large.fontSize,
  borderRadius: 5,
  backgroundColor: theme.palette.secondary[500],
  color: theme.palette.primary[400],
  borderColor: theme.palette.secondary[400],
  borderStyle: "solid",
  borderWidth: 1,
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary[50],
  fontWeight: "500",
  lineHeight: 1.25,
}));

export const BodyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary[200],
  lineHeight: 1.5,
}));

export const ListItem = styled("li")(() => ({
  marginBottom: "4px",
}));

export const ListItemIcon = styled("span")(({ theme }) => ({
  marginRight: "6px",
  color: theme.palette.success[400],
}));

export const PillItem = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: theme.palette.secondary[500],
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  width: "fit-content",
}));

export const StyledList = styled("ul")({
  lineHeight: "1.25",
  listStyle: "none",
  padding: 0,
});

export const StyledFlexList = styled("ul")({
  display: "flex",
  flexWrap: "wrap",
  lineHeight: "1",
  paddingTop: "2px",
  listStyle: "none",
  padding: 0,
});

export const SmallUpgradePrompt = styled(Stack)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  maxWidth: "100%",

  "&:hover .SmallIconContainer": {
    backgroundColor: theme.palette.secondary[600],
  },
}));

export const SmallIconContainer = styled(Box)(({ theme }) => ({
  width: 24,
  height: 24,
  display: "flex",
  flexShrink: 0,
  alignItems: "center",
  justifyContent: "center",
  fontSize: theme.typography.large.fontSize,
  borderRadius: 9999,
  color: theme.palette.primary[400],
  borderColor: theme.palette.secondary[500],
  borderStyle: "solid",
  borderWidth: 1,
}));

export const SmallUpgradePromptTextContainer = styled(Stack)(() => ({
  marginBottom: 0,
  maxWidth: "calc(100% - 34px)",
}));

export const SmallTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary[200],
  fontWeight: "500",
  lineHeight: 1,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

export const SmallBodyText = styled(Typography)(({ theme }) => ({
  maxWidth: "100%",
  color: theme.palette.secondary[300],
  lineHeight: 1,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

interface UpgradePromptContentProps {
  upgradePromptContent: {
    upgradePromptIcon?: JSX.Element;
    upgradePromptTitleText: string;
    upgradePromptBodyText: string;
    upgradePromptButtonText: string;
  };
  defaultIcon: JSX.Element;
  onClickPrimaryButton: () => void;
  onClickSecondaryButton: () => void;
  minimumPlanTierRequired: PlanTier;
  offeredPlans: any[];
  benefits: any[];
  promptBodyText: string;
  minimumIndex: number;
  size?: Size;
}

export const UpgradePromptContent: React.FC<UpgradePromptContentProps> = ({
  upgradePromptContent,
  defaultIcon,
  onClickPrimaryButton,
  onClickSecondaryButton,
  minimumPlanTierRequired,
  offeredPlans,
  benefits,
  promptBodyText,
  minimumIndex,
  size = "large",
}) => {
  function getStyleAvailability(
    tier: PlanTier,
    constraints: OfferedPlanConstraints,
  ): string[] {
    const styleKeys: Array<keyof OfferedPlanConstraints> = [
      "briaStyles",
      "openaiStyles",
      "stabilityaiStyles",
      "urlStyles",
      "bflProStyles",
    ];

    const additionalStyles: string[] = [];
    additionalStyles.push("Starter styles");

    if (tier !== "FREE") {
      additionalStyles.push("Custom styles");
    }

    const availableStyles = styleKeys
      .filter((key) => constraints[key])
      .map((key) => key);

    return additionalStyles.concat(availableStyles);
  }

  function processKey(str: string): string {
    let text = str.replace(/([a-z])([A-Z])/g, "$1 $2").toLowerCase();
    text = capitalize(text);

    if (text.startsWith("Max ")) {
      text = text.slice(4);
    }

    text = text.replace(/(\d+)([^\s])/g, "$1 $2");

    return text.trim();
  }

  function renderStyleAvailability(styles: string[]) {
    return (
      <StyledFlexList>
        {styles.map((style) => {
          const formattedStyle = styleLabelByKey[style] || style;
          return (
            <ListItem key={style}>
              <PillItem>
                <Typography variant="small">{formattedStyle}</Typography>
              </PillItem>
            </ListItem>
          );
        })}
      </StyledFlexList>
    );
  }

  function renderConstraints(features: Partial<OfferedPlanConstraints>) {
    const UNLIMITED_THRESHOLD = 999999;

    const renderListItem = (displayText: string) => (
      <ListItem>
        <Typography variant="small">
          <ListItemIcon>
            <Icon
              icon={icon({
                name: "check",
                family: "sharp",
                style: "solid",
              })}
            />
          </ListItemIcon>
          {displayText}
        </Typography>
      </ListItem>
    );

    return (
      <StyledList>
        {Object.entries(features).map(([key, value]) => {
          const formattedKey = styleLabelByKey[key] || processKey(key);

          if (typeof value === "boolean") return renderListItem(formattedKey);

          if (typeof value === "number") {
            const displayValue =
              value >= UNLIMITED_THRESHOLD ? "Unlimited" : value.toString();
            const listItemText = displayValue.concat(" ").concat(formattedKey);
            return renderListItem(capitalize(listItemText));
          }

          const displayValue = value != null ? String(value) : "";
          const listItemText = displayValue.concat(" ").concat(formattedKey);
          return renderListItem(capitalize(listItemText));
        })}
      </StyledList>
    );
  }

  if (size === "small") {
    return (
      <SmallUpgradePrompt
        direction="row"
        spacing={2.5}
        onClick={onClickPrimaryButton}
      >
        <SmallIconContainer className="SmallIconContainer">
          {upgradePromptContent.upgradePromptIcon ?? defaultIcon}
        </SmallIconContainer>

        <SmallUpgradePromptTextContainer direction="column" spacing={1}>
          <SmallTitle variant="small">
            {upgradePromptContent.upgradePromptTitleText}
          </SmallTitle>
          <SmallBodyText variant="mini">{promptBodyText}</SmallBodyText>
        </SmallUpgradePromptTextContainer>
      </SmallUpgradePrompt>
    );
  } else
    return (
      <Box sx={{ position: "relative", zIndex: 2 }}>
        <UpgradePromptBox>
          <CallToActionContainer direction="column" spacing={6}>
            <Stack direction="column" spacing={5}>
              <IconContainer>
                {upgradePromptContent.upgradePromptIcon ?? defaultIcon}
              </IconContainer>

              <UpgradePromptTextContainer direction="column" spacing={2}>
                <Title variant="title2">
                  {upgradePromptContent.upgradePromptTitleText}
                </Title>
                <BodyText variant="small">{promptBodyText}</BodyText>
              </UpgradePromptTextContainer>
            </Stack>

            <Box>
              <Grid
                container
                columnSpacing={2}
                columns={2}
                sx={{
                  marginLeft: "-8px",
                }}
              >
                <Grid item xs={1}>
                  <Button
                    fullWidth
                    onClick={onClickSecondaryButton}
                    variant="contained"
                    color="secondary"
                    sx={{ fontWeight: 400 }}
                  >
                    Compare Plans
                  </Button>
                </Grid>

                <Grid item xs={1}>
                  <Button
                    fullWidth
                    onClick={onClickPrimaryButton}
                    variant="contained"
                    color="primary"
                    sx={{ fontWeight: 500 }}
                    endIcon={
                      <Icon
                        icon={icon({
                          name: "arrow-right",
                          family: "sharp",
                          style: "solid",
                        })}
                      />
                    }
                  >
                    {upgradePromptContent.upgradePromptButtonText ??
                      `Upgrade to ${capitalize(minimumPlanTierRequired)}`}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </CallToActionContainer>

          <FeaturesList>
            <Stack direction="column" spacing={4}>
              <Stack direction="column">
                <Typography variant="micro">
                  Included with {offeredPlans[minimumIndex].name}
                </Typography>

                <FeaturesListInner spacing={1} direction="row">
                  {renderConstraints(benefits[minimumIndex].features)}
                </FeaturesListInner>
              </Stack>

              <Stack direction="column">
                <Typography variant="micro">Styles available</Typography>

                <FeaturesListInner spacing={1} direction="row">
                  {renderStyleAvailability(
                    getStyleAvailability(
                      minimumPlanTierRequired,
                      offeredPlans[minimumIndex].constraints,
                    ),
                  )}
                </FeaturesListInner>
              </Stack>
            </Stack>
          </FeaturesList>
        </UpgradePromptBox>
      </Box>
    );
};
