import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { styled, useTheme } from "@mui/system";

import PricingPlans from "../../../../features/workspaces/PricingPlans";
import { useBasicData } from "../../../../hooks";
import { Icon } from "../../../ui-v2";
import { useUpgradePromptData } from "../../../ui-v2/UpgradePrompt/hooks/useUpgradePromptData";
import UpgradePrompt from "../../../ui-v2/UpgradePrompt/UpgradePrompt";

const Container = styled("div")(({ theme }) => ({
  borderTopWidth: "1px",
  borderTopStyle: "dotted",
  borderTopColor: theme.palette.secondary[700],
  padding: theme.spacing(4, 0),
}));

export const UpgradeToTeamPrompt = () => {
  const { workspace, user } = useBasicData();
  const theme = useTheme();
  const minimumPlanTierForMembers = "STUDIO";

  const {
    workspacePlan,
    offeredPlans,
    minimumIndex,
    workspaceIndex,
    handleSelectPlan,
  } = useUpgradePromptData(minimumPlanTierForMembers, workspace, user);

  if (workspaceIndex >= minimumIndex) {
    return null;
  }

  const freeWorkspace =
    workspacePlan === "FREE" &&
    !offeredPlans[minimumIndex].canUseForPersonalWorkspace;

  return (
    <Container>
      <UpgradePrompt
        freeWorkspace={freeWorkspace}
        minimumPlanTierRequired={minimumPlanTierForMembers}
        minimumIndex={minimumIndex}
        offeredPlans={offeredPlans}
        upgradePromptContent={{
          upgradePromptIcon: (
            <Icon
              color={theme.palette.secondary[300]}
              size="small"
              icon={icon({
                name: "plus",
                family: "sharp",
                style: "solid",
              })}
            />
          ),
          upgradePromptTitleText: "Add Team Workspace",
          upgradePromptBodyText: "Collaborate on a Studio plan",
          upgradePromptButtonText: "Upgrade to Studio",
          upgradePromptModalText:
            "Forge as a team with collaborative sessions, a shared library, and an unlimited forge history. Upgrade your to a team workspace and invite your team.",
        }}
        modalContent={
          <PricingPlans targetPlan={minimumPlanTierForMembers} onboarding />
        }
        onClickUpgrade={handleSelectPlan}
        size="small"
      />
    </Container>
  );
};
