import { useCallback } from "react";
import { useMutation } from "urql";

type PlanTier = "FREE" | "PRO" | "STUDIO" | "ENTERPRISE";
type BillingPeriod = "MONTH" | "YEAR";

import { MakeWorkspaceBillingLink } from "../features/workspaces/queries.graphql";
import useShowSnackbar from "./useShowSnackbar";

function useSelectPlan() {
  const [, makeWorkspaceBillingLink] = useMutation(MakeWorkspaceBillingLink);
  const { showError } = useShowSnackbar();

  const selectPlan = useCallback(
    async (plan, billingPeriod, workspaceId, onboarding) => {
      if (!plan.canSelfServe) {
        window.open(
          `mailto:sales@layer.ai?subject=Upgrade%20To%20${plan.name}`,
          "_blank",
        );
        return;
      }

      const makeWorkspaceBillingLinkInput = {
        workspaceId: workspaceId,
        planTier: plan.tier.toUpperCase(),
        cancel: plan.tier === ("FREE" as PlanTier),
        annualBilling: billingPeriod === ("YEAR" as BillingPeriod),
        returnTo: onboarding ? "" : "/settings/billing",
      };

      try {
        const result = await makeWorkspaceBillingLink({
          makeWorkspaceBillingLinkInput,
        });
        if (result.error) {
          showError(result.error.message);
          return;
        }
        const obj = result.data.makeWorkspaceBillingLink;
        if (obj?.__typename === "Link") {
          window.open(obj.url);
        }
      } catch (error) {
        showError(error.message);
      }
    },
    [makeWorkspaceBillingLink, showError],
  );

  return selectPlan;
}

export default useSelectPlan;
