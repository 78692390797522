import { Button, Tooltip } from "@mui/material";
import classNames from "classnames";
import React from "react";

import {
  BillingPeriod,
  PlanTier,
} from "../../components/graphql/schema.graphql";
import { BasicBillingInfoFragment } from "../../hooks/queries.graphql";
import { OfferedPlan } from "../../utils/config";
import config from "../../utils/config";

interface SelectPlanButtonsProps {
  plan: OfferedPlan;
  targetPlan: PlanTier;
  isWorkspaceShared: boolean;
  billingPeriod: BillingPeriod;
  currentPlanTier: PlanTier;
  billingInfo?: BasicBillingInfoFragment;
  onClick: () => void;
}

export default function SelectPlanButtons({
  plan,
  targetPlan,
  isWorkspaceShared,
  billingPeriod,
  currentPlanTier,
  billingInfo,
  onClick,
}: SelectPlanButtonsProps) {
  const isCurrent = plan.tier === currentPlanTier;
  const isCurrentBillingPeriod = billingInfo?.period === billingPeriod;

  const planIsTeamButWorkspaceIsPersonal =
    !isWorkspaceShared &&
    !plan.canUseForPersonalWorkspace &&
    plan.canUseForSharedWorkspace;
  const planIsPersonalButWorkspaceIsTeam =
    isWorkspaceShared &&
    plan.canUseForPersonalWorkspace &&
    !plan.canUseForSharedWorkspace;

  const isDowngrade =
    config.OFFERED_PLANS.findIndex((p) => p.tier === plan.tier) <
    config.OFFERED_PLANS.findIndex((p) => p.tier === currentPlanTier);

  const isCurrentPlanEnterprise =
    plan.tier === "ENTERPRISE" && currentPlanTier === "ENTERPRISE";
  const ineligibleForUpgrade =
    !isDowngrade &&
    (planIsPersonalButWorkspaceIsTeam || planIsTeamButWorkspaceIsPersonal);
  const targetPlanIsPlan = Boolean(targetPlan) && targetPlan === plan.tier;

  let actionText: string | null = null;
  let tooltipText: string | null = null;
  const link: React.ReactNode | null = null;
  let disableButton = false;

  if (!plan.canSelfServe) {
    if (!isCurrent || !isCurrentBillingPeriod) {
      actionText = "Contact Us";
      tooltipText = "Contact our team directly";
    } else if (isCurrent) {
      actionText = "Current Plan";
      disableButton = true;
    } else {
      actionText = "Contact Us";
    }
  } else if (isCurrent) {
    if (plan.isFree) {
      actionText = "";
    } else {
      if (isCurrentBillingPeriod) {
        if (billingInfo?.isPaused) {
          actionText = "Resume";
        } else if (billingInfo?.isCancelled) {
          actionText = "Renew";
        } else if (!billingInfo?.isSubscribed) {
          actionText = "Subscribe";
        } else {
          actionText = "Current Plan";
        }
      } else {
        actionText = `Change to ${billingPeriod.toLowerCase()}ly`;
      }
    }
  } else if (planIsTeamButWorkspaceIsPersonal) {
    actionText = "Upgrade to Team";
    tooltipText =
      "This plan is available to team workspaces only. Transform this account into an team account by clicking here.";
  } else if (planIsPersonalButWorkspaceIsTeam) {
    actionText = "Unavailable";
    tooltipText =
      "This plan is available to personal workspaces only. Switch to your personal workspace to select this plan.";
    disableButton = true;
  } else if (plan.freeTrialDays && billingInfo?.eligibleForTrial) {
    actionText = "Start Free Trial";
  } else if (isDowngrade || plan.tier === "FREE") {
    actionText = "Downgrade";
  } else {
    actionText = "Upgrade";
  }

  return (
    <div className="flex flex-col mt-3 space-y-2">
      {actionText && (
        <Tooltip
          title={tooltipText}
          disableInteractive={tooltipText === "" || tooltipText === null}
          followCursor
          placement="top"
          enterDelay={250}
        >
          <div className="w-full">
            <Button
              onClick={onClick}
              fullWidth
              disabled={disableButton}
              variant="contained"
              color={disableButton || isDowngrade ? "secondary" : "primary"}
              className={classNames(
                {
                  "font-semibold bg-purple-600 text-purple-50": targetPlan
                    ? targetPlanIsPlan
                    : !isDowngrade &&
                      !ineligibleForUpgrade &&
                      !isCurrentPlanEnterprise,
                },
                {
                  "font-semibold bg-cyan-600 text-cyan-50":
                    !isDowngrade &&
                    !ineligibleForUpgrade &&
                    isCurrentPlanEnterprise,
                },
                {
                  "font-semibold bg-layer-700": targetPlan
                    ? !targetPlanIsPlan
                    : ineligibleForUpgrade && !billingInfo?.isSubscribed,
                },
                {
                  "font-semibold bg-purple-600 text-purple-50": targetPlan
                    ? targetPlanIsPlan
                    : !ineligibleForUpgrade && !billingInfo?.isSubscribed,
                },
                {
                  "font-medium bg-layer-700": isDowngrade,
                },
                {
                  "opacity-50": disableButton,
                },
                "normal-case px-2 text-sm shadow-none [text-shadow:0_1px_0_rgb(0_0_0_/_0.06)] rounded transition-all",
              )}
            >
              {actionText}
            </Button>
          </div>
        </Tooltip>
      )}
      {link}
    </div>
  );
}
