import { styled } from "@mui/system";

import { useIsOnline } from "../../../../../../hooks/useIsOnline";
import { ConnectionIndicator } from "./components/ConnectionIndicator/ConnectionIndicator";
import { WorkspaceSelectionButton } from "./components/WorkspaceSelectionButton/WorkspaceSelectionButton";

const Container = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});

export const PrimaryNavigationHeader = () => {
  const isOnline = useIsOnline();

  return (
    <Container>
      <WorkspaceSelectionButton />
      {!isOnline && <ConnectionIndicator />}
    </Container>
  );
};
