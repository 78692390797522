import { InferenceStyleParameters } from "../../../../types";

export const LIVE_CANVAS_FORM_FIELDS = {
  STYLE: "style",
  PROMPT_LANGUAGE: "promptLanguage",
  PROMPT_TEXT: "promptText",
  SEED: "seed",
} as const;

export const DEFAULT_LIVE_CANVAS_FORM_VALUES = {
  [LIVE_CANVAS_FORM_FIELDS.STYLE]: null,
  [LIVE_CANVAS_FORM_FIELDS.PROMPT_LANGUAGE]: null,
  [LIVE_CANVAS_FORM_FIELDS.PROMPT_TEXT]: "",
  [LIVE_CANVAS_FORM_FIELDS.SEED]: null,
};

export interface FormTypeRealtime {
  [LIVE_CANVAS_FORM_FIELDS.STYLE]: InferenceStyleParameters;
  [LIVE_CANVAS_FORM_FIELDS.PROMPT_LANGUAGE]: string;
  [LIVE_CANVAS_FORM_FIELDS.PROMPT_TEXT]?: string;
  [LIVE_CANVAS_FORM_FIELDS.SEED]?: number;
}

export const getDefaultLiveCanvasFormValues = () =>
  JSON.parse(JSON.stringify(DEFAULT_LIVE_CANVAS_FORM_VALUES));
