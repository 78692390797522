import { Controller } from "react-hook-form";

import { Tab, Tabs } from "../../../../../ui-v2";
import { FORM_FIELDS_3D } from "../../../../constants";

export type FormOption3D = "IMAGE" | "TEXT";
export const FORM_OPTIONS_3D: FormOption3D[] = ["IMAGE", "TEXT"] as const;

export const OptionToNameMap3D = new Map<FormOption3D, string>([
  ["IMAGE", "Image to Model"],
  ["TEXT", "Text to Model"],
]);

interface OptionSelect3DProps {
  onChange: () => void;
}

export const OptionSelect3D = (props: OptionSelect3DProps) => {
  const { onChange } = props;
  return (
    <Controller
      name={FORM_FIELDS_3D.OPTION}
      render={({ field }) => (
        <Tabs
          underline
          {...field}
          onChange={(e, newTab) => {
            field.onChange(newTab);
            field.onBlur();
            onChange();
          }}
        >
          {FORM_OPTIONS_3D.map((option) => (
            <Tab
              key={option}
              value={option}
              label={OptionToNameMap3D.get(option)}
            />
          ))}
        </Tabs>
      )}
    />
  );
};
