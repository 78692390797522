import { UseFormReturn } from "react-hook-form";
import { createStore } from "zustand";

import { SessionInferenceMode } from "../../graphql/schema.graphql";
import { FormType2D, FormType3D, FormTypeUpscale } from "../constants";
import { FormTypeRealtime } from "../forms/Realtime/constants";

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface InferenceFormProps {}

export interface InferenceFormInternalProps extends InferenceFormProps {
  activeMode: SessionInferenceMode;
  inferenceForm2D: UseFormReturn<FormType2D>;
  inferenceFormUpscale: UseFormReturn<FormTypeUpscale>;
  inferenceForm3D: UseFormReturn<FormType3D>;
  inferenceFormRealtime: UseFormReturn<FormTypeRealtime>;
}

export interface InferenceFormState extends InferenceFormInternalProps {
  setActiveMode: (mode: SessionInferenceMode) => void;
}

export const inferenceForm2DStore = (initProps: InferenceFormInternalProps) => {
  return createStore<InferenceFormState>()((set) => ({
    ...initProps,
    setActiveMode: (activeMode) => set(() => ({ activeMode })),
  }));
};

export type InferenceFormStore = ReturnType<typeof inferenceForm2DStore>;
