import "./custom.css";

import { wrapUseRoutes } from "@sentry/react";
import { ComponentType, PropsWithChildren } from "react";
import { useRoutes } from "react-router";

import { useIsMobile } from "../../hooks";
import { initSentry } from "../../utils/sentry";
import { Analytics } from "../analytics";
import { AuthProvider } from "../auth";
import ConfigChecker from "../config/ConfigChecker";
import { ErrorBoundary } from "../errors";
import MobileNotification from "../errors/MobileError";
import { UrqlProvider } from "../graphql";
import { GraphqlWsClientProvider } from "../graphql/GraphqlWsContext";
import { InferenceFormProvider } from "../inference-forms/state";
import { LayerIntercomProvider } from "../intercom/LayerIntercomProvider";
import { LayerThemeProvider } from "../theme";
import { LayerSnackbarProvider } from "../ui/snackbar";
import { VersionChecker } from "../version-checker/VersionChecker";
import { routes } from ".";

initSentry();

// Routes usually has JSX children but because we already have the object form
// we have to create our own Routes component with statically imported routes.
function StaticRoutes() {
  const useSentryRoutes = wrapUseRoutes(useRoutes);
  return useSentryRoutes(routes);
}

type AppProps = { router: ComponentType<PropsWithChildren> };

export default function App({ router: Router }: AppProps) {
  const isMobile = useIsMobile();

  if (isMobile && !__DEV__) {
    return <MobileNotification />;
  }

  return (
    <LayerThemeProvider>
      <ErrorBoundary topLevel>
        <Router>
          <LayerSnackbarProvider>
            <ConfigChecker>
              <AuthProvider>
                <GraphqlWsClientProvider>
                  <UrqlProvider>
                    <LayerIntercomProvider>
                      <VersionChecker />
                      <Analytics />
                      {/* InferenceFormProvider is needed here as that form is referred to in several parts of the app, like sessions, drive, style gallery and prompt history */}
                      <InferenceFormProvider>
                        <StaticRoutes />
                      </InferenceFormProvider>
                    </LayerIntercomProvider>
                  </UrqlProvider>
                </GraphqlWsClientProvider>
              </AuthProvider>
            </ConfigChecker>
          </LayerSnackbarProvider>
        </Router>
      </ErrorBoundary>
    </LayerThemeProvider>
  );
}
