import { Box, styled } from "@mui/system";
import { Link } from "react-router-dom";

import { SessionInferenceMode } from "../../../graphql/schema.graphql";
import { Chip, Tooltip, Typography } from "../../../ui-v2";
import { AppMenuItem, CreateMenuItemId } from "../../Nav";
import { useNavigationStore } from "../../state";
import { UpgradeToTeamPrompt } from "../UpgradeToTeamPrompt/UpgradeToTeamPrompt";

interface NavItemsContainerProps {
  items: AppMenuItem[];
  createMenuItems: AppMenuItem[];
  isPersonalWorkspace: boolean;
  inferenceMode: SessionInferenceMode;
}

const EXPANDED_GAP = "7px";

const NavItemsWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "expanded",
})<{ expanded: boolean }>(({ theme, expanded }) => ({
  display: "flex",
  flexDirection: "column",
  transition: "all 300ms ease-out",
  gap: expanded ? theme.spacing(1) : EXPANDED_GAP,
  paddingTop: expanded ? theme.spacing(2.5) : EXPANDED_GAP,
}));

const StyledNavLink = styled(Link, {
  shouldForwardProp: (prop) =>
    prop !== "expanded" && prop !== "disabled" && prop !== "isActive",
})<{ isActive: boolean; expanded?: boolean; disabled?: boolean }>(
  ({ theme, expanded, isActive, disabled = false }) => ({
    height: theme.spacing(7),
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
    borderRadius: theme.spacing(1),
    color: theme.palette.secondary[300],
    border: "none",
    boxShadow: "inset 0 0 0 0.5px transparent",

    ...(expanded
      ? {
          "&:hover": {
            backgroundColor: theme.palette.secondary[900],
            boxShadow: `inset 0 0 0 0.5px ${theme.palette.secondary["700/50"]}`,
          },
          backgroundColor: isActive
            ? theme.palette.secondary[900]
            : "transparent",
          boxShadow: isActive
            ? `inset 0 0 0 0.5px ${theme.palette.secondary["700/50"]}`
            : "inset 0 0 0 0.5px transparent",
        }
      : {
          ".MuiTypography-root": {
            display: "none",
          },

          "&:hover": {
            backgroundColor: theme.palette.secondary[900],
            boxShadow: `inset 0 0 0 0.5px ${theme.palette.secondary["700/50"]}`,
          },
          color: isActive ? theme.palette.secondary[50] : "inherit",
          backgroundColor: isActive
            ? theme.palette.secondary[900]
            : "transparent",
          boxShadow: isActive
            ? `inset 0 0 0 0.5px ${theme.palette.secondary["700/50"]}`
            : "inset 0 0 0 0.5px transparent",
        }),

    opacity: disabled ? 0.4 : 1,
    pointerEvents: disabled ? "none" : "auto",
  }),
);

const IconContainer = styled(Box)(({ theme }) => ({
  width: theme.spacing(7),
  height: theme.spacing(7),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexShrink: 0,
}));

const CreateHeaderSection = styled(Box, {
  shouldForwardProp: (prop) => prop !== "expanded",
})<{ expanded: boolean }>(({ theme, expanded }) => ({
  display: "flex",
  userSelect: "none",
  transition: "all 300ms ease-out",
  overflow: "hidden",
  pointerEvents: "none",
  ...(expanded
    ? {
        height: "18px",
        opacity: 1,
        paddingBottom: theme.spacing(-0.5),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        marginTop: theme.spacing(5),
      }
    : {
        height: "0px",
        opacity: 0,
      }),
}));

export const NavItemsContainer = (props: NavItemsContainerProps) => {
  const { items, createMenuItems, isPersonalWorkspace, inferenceMode } = props;
  const { expanded } = useNavigationStore();

  const isUpgradeToTeamPlanVisible = isPersonalWorkspace && expanded;
  return (
    <>
      <NavItemsWrapper expanded={expanded}>
        {items.map((item) => {
          const isActive =
            location.pathname.includes(item.id) ||
            item.route === location.pathname;
          return (
            <Tooltip
              key={`nav-menu-item-${item.id}`}
              title={expanded ? "" : item.name}
              enterDelay={500}
              placement="right"
              arrow
            >
              <StyledNavLink
                to={item.route}
                onClick={item.onClick ? item.onClick : () => {}}
                expanded={expanded}
                isActive={isActive}
                disabled={item.disabled}
              >
                <IconContainer>
                  {isActive && item.activeIcon ? item.activeIcon : item.icon}
                </IconContainer>
                {expanded && (
                  <Typography
                    variant="small"
                    fontWeight={500}
                    color="secondary.100"
                  >
                    {item.name}
                  </Typography>
                )}
              </StyledNavLink>
            </Tooltip>
          );
        })}
      </NavItemsWrapper>

      {createMenuItems.length > 0 && (
        <CreateHeaderSection expanded={expanded}>
          <Typography variant="mini">Create</Typography>
        </CreateHeaderSection>
      )}

      <NavItemsWrapper expanded={expanded}>
        {createMenuItems.map((item) => {
          let isActive = false;
          switch (item.id as CreateMenuItemId) {
            case "canvas":
              isActive = location.pathname.includes("assets");
              break;
            case "forge":
              isActive =
                location.pathname.includes("session") &&
                inferenceMode === "CREATE";
              break;
            case "upscale":
              isActive =
                location.pathname.includes("session") &&
                inferenceMode === "UPSCALE";
              break;
            case "3d":
              isActive =
                location.pathname.includes("session") &&
                inferenceMode === "CREATE_3D";
              break;
            default:
              break;
          }

          return (
            <Tooltip
              key={`nav-menu-item-${item.id}`}
              title={expanded ? "" : `New ${item.name}`}
              enterDelay={500}
              placement="right"
              arrow
            >
              <StyledNavLink
                to={item.route}
                state={item.state}
                expanded={expanded}
                isActive={isActive}
              >
                <IconContainer>
                  {isActive && item.activeIcon ? item.activeIcon : item.icon}
                </IconContainer>
                <Typography
                  variant="small"
                  fontWeight={500}
                  color="secondary.100"
                >
                  {item.name}
                </Typography>
                {item.id === "3d" && expanded && (
                  <Chip
                    label="BETA"
                    size="small"
                    variant="filled"
                    color="secondary"
                    sx={{
                      fontSize: 8,
                      height: 16,

                      "& .MuiChip-label": {
                        pr: 0.75,
                        pl: 0.75,
                      },
                    }}
                  />
                )}
              </StyledNavLink>
            </Tooltip>
          );
        })}
      </NavItemsWrapper>
      {isUpgradeToTeamPlanVisible && (
        <Box sx={{ mt: 2 }}>
          <UpgradeToTeamPrompt />
        </Box>
      )}
    </>
  );
};
