import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function LoadingSpinner() {
  return (
    <FontAwesomeIcon
      icon={icon({ name: "spinner-third" })}
      className="text-violet-600 dark:text-violet-500 animate-spin"
    />
  );
}
