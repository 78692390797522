import pluralize from "pluralize";

import { OfferedPlan } from "../../utils/config";
import { BillingWorkspace } from "./module";
import { formatCents, humanDateString } from "./utils";

interface PlanSummaryProps {
  plan: OfferedPlan;
  workspace: BillingWorkspace;
}

export default function PlanSummary({ plan, workspace }: PlanSummaryProps) {
  if (!plan || !workspace?.billingInfo) {
    return null;
  }

  return (
    <div className="flex flex-col space-y-4">
      <div className="w-full text-sm font-normal leading-none text-layer-600 dark:text-layer-300">
        <span>You are currently on the </span>
        <span className="font-bold text-layer-700 dark:text-layer-300">
          {plan.name}
        </span>
        <span> plan</span>
        {workspace.planSeats ? (
          <>
            <span> with </span>
            <span className="font-bold text-layer-700 dark:text-layer-300">
              {workspace.planSeats} {pluralize("seat", workspace.planSeats)}
            </span>
          </>
        ) : null}
        <span>.</span>
        {!plan.isFree && (
          <>
            <span> It costs </span>
            <span className="font-bold text-layer-700 dark:text-layer-300">
              {workspace.billingInfo.periodPriceAmount === null
                ? "a custom amount"
                : formatCents(workspace.billingInfo.periodPriceAmount)}
            </span>
            <span> per </span>
            <span className="font-bold text-layer-700 dark:text-layer-300">
              {workspace.billingInfo.period.toLowerCase()}
            </span>
            <span>.</span>
          </>
        )}
        {workspace.billingInfo.isCancelled ? (
          <>
            <span>
              {" "}
              Your subscription has been cancelled and is going to end on{" "}
            </span>
            <span className="font-bold text-layer-700 dark:text-layer-300">
              {humanDateString(workspace.billingInfo.periodEndAt)}
            </span>
            <span>.</span>
          </>
        ) : (
          workspace.billingInfo.isTrial && (
            <>
              <span>
                {` We will ${
                  workspace.billingInfo.paymentMethodSummary
                    ? "start charging your account"
                    : "cancel your plan"
                } after your trial ends on `}
              </span>
              <span className="font-bold text-layer-700 dark:text-layer-300">
                {humanDateString(workspace.billingInfo.periodEndAt)}
              </span>
              <span>.</span>
            </>
          )
        )}
      </div>
    </div>
  );
}
