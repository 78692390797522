import LoadingSpinnerPage from "../../common/LoadingSpinnerPage";
import { ErrorWithDataFragment } from "../../hooks/queries.graphql";
import Error403 from "./Error403";
import Error404 from "./Error404";
import Error500 from "./Error500";

interface HandlerErrorProps {
  error: ErrorWithDataFragment;
  fetching: boolean;
  dataMissing?: boolean;
  loadingComponent?: JSX.Element;
}

export function errorHandlerComponent({
  error,
  fetching,
  dataMissing,
  loadingComponent = <LoadingSpinnerPage />,
}: HandlerErrorProps) {
  if (error) {
    if (error.code === "NOT_FOUND") {
      return <Error404 />;
    } else if (error.code === "UNAUTHORIZED") {
      return <Error403 />;
    }
    return <Error500 />;
  } else if (dataMissing) {
    if (fetching) {
      return loadingComponent;
    }
    return <Error404 />;
  }
  return null;
}
