import { styled } from "@mui/system";
import { FC, PropsWithChildren } from "react";

const Container = styled("nav")(({ theme }) => ({
  position: "fixed",
  left: 0,
  top: 0,
  bottom: 0,
  width: 220,
  height: "100%",
  transition: "width 300ms ease-out",
  zIndex: 200,
  backgroundColor: theme.palette.secondary["1000"],
  padding: theme.spacing(3, 2, 0, 2),
}));

const InnerContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
  position: "relative",
  overflow: "auto",
});

export const PrimaryNavigationContainer: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  return (
    <Container>
      <InnerContainer>{children}</InnerContainer>
    </Container>
  );
};
