import { BillingPeriod } from "../../components/graphql/schema.graphql";
import { OfferedPlan } from "../../utils/config";

export function formatCents(cents: number) {
  if (cents === 0) {
    return "$0";
  }
  const centsRemainder = cents % 100;
  return `$${Math.floor(cents / 100).toLocaleString("en-US")}${
    centsRemainder ? "." + centsRemainder.toString() : ""
  }`;
}

export function humanDateString(date: string) {
  return new Date(date).toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
}

export function calculateDiscount(
  plan: OfferedPlan,
  period: BillingPeriod,
  discountPercent?: number,
) {
  if (plan.tier === "ENTERPRISE") {
    return 0;
  }
  let price = 1;
  if (discountPercent) {
    price = price * (1 - discountPercent / 100);
  }
  if (
    plan.monthSubscriptionPrice &&
    plan.yearSubscriptionPrice &&
    period === "YEAR"
  ) {
    price =
      price * (plan.yearSubscriptionPrice / plan.monthSubscriptionPrice / 12);
  }
  return Math.round((1 - price) * 100);
}
