import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { styled } from "@mui/material";
import { useLocation } from "react-router-dom";

import { useBasicData } from "../../../../../../hooks";
import { Icon } from "../../../../../ui-v2";
import { PrimaryNavigationLink } from "./components/PrimaryNavigationLink/PrimaryNavigationLink";

const Container = styled("nav")({
  display: "flex",
  flexDirection: "column",
});

export const PrimaryNavigationLinks = () => {
  const { pathname } = useLocation();
  const { workspace } = useBasicData();

  return (
    <Container>
      <PrimaryNavigationLink
        label="Home"
        to={`/${workspace.name}`}
        active={pathname === `/${workspace.name}`}
        icon={
          <Icon
            size="medium"
            icon={icon({
              name: "house-window",
              family: "sharp",
              style: "regular",
            })}
          />
        }
        activeIcon={
          <Icon
            size="medium"
            icon={icon({
              name: "house-window",
              family: "sharp",
              style: "solid",
            })}
          />
        }
      />
      <PrimaryNavigationLink
        label="Drive"
        to={`/${workspace.name}/drive`}
        active={pathname.startsWith(`/${workspace.name}/drive`)}
        icon={
          <Icon
            size="medium"
            icon={icon({
              name: "layer-group",
              family: "sharp",
              style: "regular",
            })}
          />
        }
        activeIcon={
          <Icon
            size="medium"
            icon={icon({
              name: "layer-group",
              family: "sharp",
              style: "solid",
            })}
          />
        }
      />
      <PrimaryNavigationLink
        label="Styles"
        to={`/${workspace.name}/styles`}
        active={pathname.startsWith(`/${workspace.name}/styles`)}
        icon={
          <Icon
            size="medium"
            icon={icon({
              name: "palette",
              family: "sharp",
              style: "regular",
            })}
          />
        }
        activeIcon={
          <Icon
            size="medium"
            icon={icon({
              name: "palette",
              family: "sharp",
              style: "solid",
            })}
          />
        }
      />
      <PrimaryNavigationLink
        label="History"
        to={`/${workspace.name}/prompts`}
        active={pathname.startsWith(`/${workspace.name}/prompts`)}
        icon={
          <Icon
            size="medium"
            icon={icon({
              name: "clock-rotate-left",
              family: "sharp",
              style: "regular",
            })}
          />
        }
        activeIcon={
          <Icon
            size="medium"
            icon={icon({
              name: "clock-rotate-left",
              family: "sharp",
              style: "solid",
            })}
          />
        }
      />
    </Container>
  );
};
