import classNames from "classnames";

import { BillingPeriod } from "../../components/graphql/schema.graphql";
import { OfferedPlan } from "../../utils/config";
import { calculateDiscount, formatCents } from "./utils";

interface PriceBannerProps {
  plan: OfferedPlan;
  period: BillingPeriod;
  discountPercent?: number;
  isPerSeat: boolean;
}

export default function PriceBanner({
  plan,
  period,
  discountPercent,
  isPerSeat,
}: PriceBannerProps) {
  let cents = 0;
  let suffix = "per";
  if (isPerSeat) {
    suffix += " seat /";
  }
  switch (period) {
    case "MONTH":
      cents = plan.monthSubscriptionPrice;
      suffix += " month";
      break;
    case "YEAR":
      cents = plan.yearSubscriptionPrice;
      suffix += " year";
      break;
  }
  if (cents && discountPercent) {
    cents = cents * (1 - discountPercent / 100);
  }
  if (cents === null) {
    suffix = "";
  }
  return (
    <>
      <span className="relative text-3xl font-medium text-layer-900 dark:text-layer-50">
        {cents === null ? "Contact Us" : formatCents(cents)}
        <span
          className={classNames(
            "absolute -right-10 ring-1 leading-none text-xs ml-2 py-0.5 px-1 rounded-lg bg-transparent ring-layer-500/50 text-layer-300 -translate-y-1 -translate-x-0.5",
            calculateDiscount(plan, period, discountPercent)
              ? "inline-flex"
              : "hidden",
          )}
        >
          -{calculateDiscount(plan, period, discountPercent)}%
        </span>
      </span>
      {cents !== 0 && (
        <span className="text-xs font-normal text-layer-700 dark:text-layer-300">
          {suffix}
        </span>
      )}
    </>
  );
}
