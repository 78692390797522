import * as yup from "yup";

import {
  MAX_SEED,
  MAX_STYLE_WEIGHT,
  MIN_SEED,
  MIN_STYLE_WEIGHT,
} from "../../constants";
import { FormTypeRealtime, LIVE_CANVAS_FORM_FIELDS } from "./constants";

export const validationSchemaRealtime = yup.object().shape({
  [LIVE_CANVAS_FORM_FIELDS.PROMPT_TEXT]: yup.string(),
  [LIVE_CANVAS_FORM_FIELDS.STYLE]: yup.object().shape({
    id: yup.string(),
    weight: yup
      .number()
      .typeError("Invalid value.")
      .min(MIN_STYLE_WEIGHT, `Weight must be at least ${MIN_STYLE_WEIGHT}.`)
      .max(MAX_STYLE_WEIGHT, `Weight must be at most ${MAX_STYLE_WEIGHT}.`),
  }),
  [LIVE_CANVAS_FORM_FIELDS.SEED]: yup
    .number()
    .nullable()
    .test(
      "is-valid-seed",
      `Seed must be empty or an integer between ${MIN_SEED} and ${MAX_SEED}.`,
      (value) => value === null || (value >= MIN_SEED && value <= MAX_SEED),
    ),
}) as yup.ObjectSchema<FormTypeRealtime>;
