import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

import useBasicData from "../../hooks/useBasicData";
import {
  DAY_IN_MILLISECONDS,
  WEEK_IN_MILLISECONDS,
} from "../../utils/constants";
import TeamNotices from "./TeamNotices";

const shouldShowNotice = (notice, lastSeenAt, lastLoginAt) => {
  if (!notice.isLive || !notice.content || notice.content.length === 0) {
    return false;
  }
  const noticeDate = new Date(notice.updatedAt);
  if (!lastSeenAt || noticeDate > lastSeenAt) {
    return true;
  }

  switch (notice.frequency) {
    case "ONCE":
      return false;
    case "DAILY":
      return noticeDate < new Date(lastSeenAt.getTime() - DAY_IN_MILLISECONDS);
    case "WEEKLY":
      return noticeDate < new Date(lastSeenAt.getTime() - WEEK_IN_MILLISECONDS);
    case "PER_LOGIN":
      return !lastLoginAt || lastLoginAt > lastSeenAt;
  }
  return false;
};

const TeamNoticesWrapper = ({ children }) => {
  const { getIdTokenClaims } = useAuth0();
  const { workspace } = useBasicData({ fetch: true });
  const [lastLoginAt, setLastLoginAt] = useState<Date | null>(null);

  useEffect(() => {
    const checkLoginClaims = async () => {
      const claims = await getIdTokenClaims();
      if (claims?.iat) {
        setLastLoginAt(new Date(claims.iat * 1000));
      }
    };
    checkLoginClaims();
  }, [getIdTokenClaims]);

  const unseenNotices = workspace?.notices?.notices
    ?.filter((notice) =>
      shouldShowNotice(
        notice,
        workspace.myNoticeDismissedAt
          ? new Date(workspace.myNoticeDismissedAt)
          : undefined,
        lastLoginAt,
      ),
    )
    .map((notice) => notice.content);

  if (unseenNotices?.length) {
    return (
      <TeamNotices
        workspaceId={workspace?.id}
        workspaceName={workspace?.name}
        workspaceLogo={workspace?.profilePicture}
        notices={unseenNotices}
      />
    );
  }

  return <>{children}</>;
};

export default TeamNoticesWrapper;
